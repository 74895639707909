// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import TaskIcon from "@mui/icons-material/Task";
import LockIcon from "@mui/icons-material/Lock";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HelpIcon from "@mui/icons-material/Help";
import CategoryIcon from "@mui/icons-material/Category";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PublicIcon from "@mui/icons-material/Public";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SchoolIcon from "@mui/icons-material/School";

const navigation = () => {
  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/dashboard",
    },
    // {
    //   title: "Privacy Policy",
    //   icon: LockIcon,
    //   path: "/privacy-policy",
    // },
    // {
    //   title: "Terms of service",
    //   icon: TaskIcon,
    //   path: "/terms-conditions",
    // },
    {
      title: "Contact Us",
      icon: ContactPageIcon,
      path: "/contact-us",
    },
    {
      title: "Success Stories",
      icon: ContactPageIcon,
      path: "/success-stories",
    },
    {
      title: "Client Category",
      icon: ContactPageIcon,
      path: "/client-category",
    },
    {
      title: "Career",
      icon: SchoolIcon,
      path: "/career",
    },
    {
      title: "Blogs",
      icon: ArticleIcon,
      path: "/blog",
    },

    {
      title: "IT Solutions",
      icon: ArticleIcon,
      path: "/it-solutions",
    },
  ];
};

export default navigation;
