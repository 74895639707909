// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import TaskIcon from "@mui/icons-material/Task";
import LockIcon from "@mui/icons-material/Lock";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HelpIcon from "@mui/icons-material/Help";
import CategoryIcon from "@mui/icons-material/Category";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PublicIcon from "@mui/icons-material/Public";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SchoolIcon from "@mui/icons-material/School";

const subNavigation = () => {
  const itSolutionId = localStorage.getItem("solutionId");

  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/dashboard",
    },

    {
      title: "Overview",
      icon: ContactPageIcon,
      children: [
        {
          title: "Overview List",
          icon: ContactPageIcon,
          path: `/it-solution-overview/${itSolutionId}`,
        },
        {
          title: "Overview Plan",
          icon: ContactPageIcon,
          path: `/it-solution-overview-plan/${itSolutionId}`,
        },
        {
          title: "Overview Features",
          icon: ContactPageIcon,
          path: `/it-solution-overview-features/${itSolutionId}`,
        },
      ],
    },
    {
      title: "Features",
      icon: ContactPageIcon,

      children: [
        {
          title: "Features List",
          icon: ContactPageIcon,
          path: `/it-solution-features/${itSolutionId}`,
        },
        {
          title: "Additional Features",
          icon: ContactPageIcon,
          path: `/it-solution-additional-features/${itSolutionId}`,
        },
      ],
    },
    // {
    //   title: "Demo",
    //   icon: ContactPageIcon,
    //   path: "/",
    // },
    // {
    //   title: "Resources",
    //   icon: SchoolIcon,
    //   path: "/",
    // },
    // {
    //   title: "Get Quote",
    //   icon: ArticleIcon,
    //   path: "/",
    // },

    // {
    //   title: "Support",
    //   icon: ArticleIcon,
    //   path: "/",
    // },
  ];
};

export default subNavigation;
